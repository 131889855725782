import React, { useRef, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { useInView } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";

import Ship from "../components/ShipImage";
import MainLayout from "../components/MainLayout";
import Heading from "../components/Heading";
import Seo from "../components/Head";
import Prefooter from "../components/Prefooter";
import { H2, H3, Paragraph } from "../components/Utilities";
import Salt from "../components/Salt";
import Coin from "../components/Coin";

import Knuckles from "../images/about-vssl/rings.jpg";
import Anchor from "../images/about-vssl/anchor.png";
import Boat from "../images/about-vssl/boat.jpg";
import Glasses from "../images/about-vssl/cheers.jpg";

const Channels = (props) => {
  return (
    <p className="px-5 pb-5 text-smoke text-lg elza font-light leading-[27px] tracking-tight">
      {props.channels.map((channel, index) => {
        if (props.channels.length > 1) {
          return (
            <span key={channel.title}>
              {index !== props.channels.length - 1 ? (
                ""
              ) : (
                <span className="inline-block mx-1">and</span>
              )}
              <span className="font-bold">{channel.title}</span>
            </span>
          );
        } else {
          return (
            <span key={channel.title} className="font-bold">
              {channel.title}
            </span>
          );
        }
      })}
    </p>
  );
};

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [easterEggClickCounter, setEasterEggClickCounter] = useState(0);

  const easterEgg = () => {
    setEasterEggClickCounter((prev) => (prev += 1));
    if (easterEggClickCounter === 0) {
      console.log(
        "What's up nerd! Enjoying your console adventure? There's nothing else here."
      );
    } else if (easterEggClickCounter === 1) {
      console.log("Really though. There isn't anything here.");
    } else if (easterEggClickCounter === 2) {
      console.log("You definitely have too much free time on your hands.");
    } else if (easterEggClickCounter === 3) {
      console.log("You really like clicking, don't you? Still nothing here.");
    } else if (easterEggClickCounter === 4) {
      console.log(
        "Seriously, stop clicking. You're not going to find anything new."
      );
    } else if (easterEggClickCounter === 5) {
      console.log("Seriously.");
    } else if (easterEggClickCounter === 6) {
      console.log("Come on now.");
    } else if (easterEggClickCounter === 7) {
      console.log("Nope.");
    } else {
      console.log("You're pretty annoying. https://dos.zone/doom-dec-1993/");
    }
  };
  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(filter: { title: { eq: "About VSSL" } }) {
            edges {
              node {
                about {
                  anchorTitle
                  anchorDescription
                  commanderCoinDescription
                  commanderCoinTitle
                  heroDescription
                  heroSalt
                  heroTitle
                  metaTitle
                  metaDescription
                  involvedTitle
                  clubs {
                    description
                    title
                    slackChannels {
                      title
                    }
                  }
                }
                template {
                  ... on WpDefaultTemplate {
                    footerContent {
                      footerTitle
                      footerDescription
                      footerBackground {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <MainLayout>
          <Seo
            meta_title={props.allWpPage.edges[0].node.about.metaTitle}
            meta_title_backup={props.allWpPage.edges[0].node.about.heroTitle}
            meta_description={
              props.allWpPage.edges[0].node.about.metaDescription
            }
          />
          <section
            className="lg:pt-[65px] max-w-screen-2xl mx-auto md:mb-10"
            id="hero"
          >
            <div className="grid md:grid-cols-6 items-start md:pl-28 relative mb-[450px] md:mb-0">
              <div className="col-span-4 relative z-10">
                <div className="bg-iron px-8 pt-32 pb-20 md:pt-32 lg:pt-24 md:pb-28 md:px-10 lg:px-24 lg:p-28">
                  <Heading
                    text={props.allWpPage.edges[0].node.about.heroTitle}
                    alignment={"justify-start"}
                  />
                  <Paragraph
                    content={
                      props.allWpPage.edges[0].node.about.heroDescription
                    }
                    classes={"text-smoke mt-7"}
                  />
                </div>
                <div className="grid grid-cols-2">
                  <div className="-translate-y-10">
                    <div className="w-40 ml-auto translate-x-10 md:w-52 md:translate-x-0 lg:translate-x-10">
                      <StaticImage
                        src="../images/about-vssl/fire.jpg"
                        alt="Bonfire in front of lake"
                      />
                    </div>
                    <img
                      src={Knuckles}
                      alt="Knuckles with skeleton rings"
                      className="w-80 ml-auto md:hidden lg:block"
                    />
                  </div>
                  <div className="relative">
                    <StaticImage
                      src="../images/about-vssl/sinking.jpg"
                      alt="Hand reaching out of water"
                    />
                  </div>
                </div>
                <div className="absolute bottom-40 -right-5 xl:bottom-10 xl:-right-5 hidden md:block">
                  <Salt
                    title={props.allWpPage.edges[0].node.about.heroSalt}
                    range={[0, 0.25]}
                  />
                </div>
              </div>
              <div className="col-span-4 md:col-span-2 absolute top-[675px] sm:top-[300px] md:top-0 left-0 md:relative z-0">
                <StaticImage
                  src="../images/about-vssl/hanging.jpg"
                  alt="Illustration of sailors"
                />
                <div className="absolute -bottom-32 right-0 md:right-auto">
                  <Ship />
                  <img
                    src={Boat}
                    alt="Boat tied to dock"
                    className="w-52 md:w-96 -translate-x-52 -translate-y-32 md:opacity-0 lg:opacity-100 md:translate-x-0 md:translate-y-24"
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="commander-coins" className="max-w-screen-xl mx-auto">
            <div className="relative grid items-center lg:grid-cols-7 md:gap-x-32 pt-10 pb-20 lg:py-32 px-8 lg:px-20">
              <div className="order-2 lg:order-1 lg:col-span-4">
                <H3
                  title={props.allWpPage.edges[0].node.about.commanderCoinTitle}
                  classes={"text-smoke"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.about.commanderCoinDescription
                  }
                  classes={"text-smoke xl:pr-20"}
                />
              </div>
              <div className="relative order-1 lg:order-2 mb-10 lg:mb-0 lg:col-span-3 sm:grid sm:grid-cols-2 lg:block">
                <Coin />
                <StaticImage
                  src="../images/about-vssl/happy-hour.jpg"
                  alt="Beers and Commander Coin on table"
                  className="ml-10 sm:ml-auto lg:ml-0 w-48 sm:w-80 relative z-10"
                />
                <div className="ml-auto w-52 sm:w-96 md:-translate-x-20 lg:translate-x-0 lg:w-80">
                  <StaticImage
                    src="../images/about-vssl/coins.jpg"
                    alt="Ancient coins"
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="anchor" className="max-w-screen-xl mx-auto">
            <div className="relative grid items-center lg:grid-cols-7 md:gap-x-32 pt-16 pb-20 lg:pt-16 lg:pb-32 px-8 lg:px-20">
              <div className="order-2 lg:col-span-4 xl:pr-20">
                <H3
                  title={props.allWpPage.edges[0].node.about.anchorTitle}
                  classes={"text-smoke"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.about.anchorDescription
                  }
                  classes={"text-smoke"}
                />
              </div>
              <div className="order-1 mb-10 lg:mb-0 grid grid-cols-2 lg:col-span-3 relative">
                <img
                  src={Anchor}
                  alt="Anchor illustration sticker"
                  className="absolute w-28 sm:w-40 -top-16 sm:top-0 lg:-top-20 left-32 sm:left-52 lg:left-28 z-10 animate-anchor"
                />
                <div className="-translate-y-16 sm:-translate-x-20 w-[215px] md:w-[300px]">
                  <StaticImage
                    src="../images/about-vssl/chyea.jpg"
                    alt="Hand in ocean throwing up shaka sign"
                  />
                </div>
                <div className="relative">
                  <div className="translate-y-10 sm:translate-y-0 sm:-translate-x-20 lg:-translate-x-10 w-40 sm:w-60">
                    <StaticImage
                      src="../images/about-vssl/award.jpg"
                      alt="VSSL Anchor Award"
                    />
                  </div>
                  <button
                    onClick={easterEgg}
                    className="focus:outline-none -translate-x-8 sm:-translate-x-20 translate-y-10 sm:translate-y-0 w-32 sm:w-52 transition-all duration-400 hover:rotate-6"
                  >
                    <img
                      src={Glasses}
                      alt="VSSL Captain's Glasses"
                      className="w-full h-full"
                    />
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section
            className="max-w-screen-xl mx-auto lg:mb-20 relative z-10"
            id="involved"
            ref={ref}
          >
            <div className="bg-bronze grid lg:grid-cols-2 px-8 py-14 lg:px-20 lg:py-28">
              <H2
                title={props.allWpPage.edges[0].node.about.involvedTitle}
                classes={""}
              />
              <div className="grid md:grid-cols-2 lg:col-span-2 gap-7 mt-14 lg:mt-20">
                {props.allWpPage.edges[0].node.about.clubs.map(
                  (club, index) => {
                    return (
                      <div
                        key={club.title}
                        className="border border-ink"
                        style={{
                          transform: isInView ? "none" : "translateY(150px)",
                          opacity: isInView ? 1 : 0,
                          transition: `all 1s ease-in-out ${
                            (index + 1) * 0.2
                          }s`,
                        }}
                      >
                        <p className="border-b border-ink text-ink text-[28px] gin-regular uppercase leading-loose px-5">
                          {club.title}
                        </p>
                        <p className="px-5 pt-5 pb-3 text-smoke text-lg elza font-light leading-[27px] tracking-tight">
                          {club.description}
                        </p>
                        <Channels channels={club.slackChannels} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </section>

          <Prefooter
            title={
              props.allWpPage.edges[0].node.template.footerContent.footerTitle
            }
            description={
              props.allWpPage.edges[0].node.template.footerContent
                .footerDescription
            }
            background={
              props.allWpPage.edges[0].node.template.footerContent
                .footerBackground
            }
          />
        </MainLayout>
      )}
    />
  );
};
export default About;
