import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "../hooks/useIsMobile";

export default function Coin() {
  const isMobile = useIsMobile(576);
  const [coinAnimating, setCoinAnimating] = useState(false);
  const animateOnHover = () =>
    !isMobile ? setCoinAnimating((prev) => !prev) : null;
  return (
    <div
      role="presentation"
      className={`absolute z-20 w-32 lg:w-36 transition-all duration-[2.5s] ${
        coinAnimating
          ? "-top-10 left-52 rotate-[360deg]"
          : "top-40 left-2 lg:top-52 lg:-left-16"
      }`}
      onMouseEnter={animateOnHover}
    >
      <StaticImage
        src="../images/about-vssl/commander-coin.png"
        alt="VSSL Commander Coin"
      />
    </div>
  );
}
