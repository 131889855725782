import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useScroll, useTransform } from "framer-motion";
import loadable from "@loadable/component";

const isBrowser = typeof window !== "undefined";

const ShipComponent = () => {
  const { scrollYProgress } = useScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], [100, -350], {
    clamp: true,
  });

  return (
    <motion.div
      className="w-60 ml-auto md:ml-0 relative z-20"
      style={{ translateY: translateY }}
    >
      <StaticImage
        src="../images/about-vssl/ship-model.jpg"
        alt="Model of ship being held by hand"
      />
    </motion.div>
  );
};

const LoadableShip = loadable(() =>
  isBrowser ? Promise.resolve(ShipComponent) : Promise.resolve(() => null)
);

export default function Ship() {
  return (
    <>
      <LoadableShip />
    </>
  );
}
